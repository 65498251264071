import React from 'react';
import close from "../../content/assets/img/icon-close.svg"
import "./popup.css"

function Popup(props){
    return (
        <section className={"bot-popup"+(props.className ? " "+props.className : "")}>
            <div className="bot-popup-window">
                <div className="bot-popup__in">
                    <button className="bot-policy-close" onClick={props.onClose}>
                        {
                        !props.noClose && <img src={close} className="bot-icon-close" alt="close"></img>
                        }
                    </button>
                    <div className="bot-popup-content color-black">
                    <div className="mb2">
                            {props.children}
                    </div>
                    <div className="bot-btns df">
                    {
                        !props.noButton && <button className="bot-btn" onClick={props.onClose}>{props.buttonText  || "OK"}</button>
                    }
                    </div>
                    </div>
                </div>

            </div>
    </section>
    )
}

export default Popup